<template>
  <div class='card'>
    <div class='container'>


      <div class='row'>
        <h2>Update Vehicle Pricing</h2>
      </div>
      <div class='row'>
      
      </div>
      <div class='row'>

        <form id="upload_form" enctype="multipart/form-data" method="post">
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <input type="file" name="fileUploader" id="fileUploader" class="btn btn-default">
                </div>
                <div class="col-md-4" style="text-align:right;">
                    <input type="button" id="btnUpload"  class="btn btn-default" value="Upload File" @click='upload()' />
                </div>
            </div>
            <div class="row">
              <v-runtime-template :template="template"></v-runtime-template>
            </div>
        </div>
        </form>   
        <br>
      </div>

    </div>
  </div>
</template>
<script>
import VRuntimeTemplate from "v-runtime-template";
import VueNotifications from 'vue-notifications';
import {    Datatable  } from '../../mdb/components/Datatable';
import {    mdbProgress   } from '../../mdb/components/Progress';
import miniToastr from 'mini-toastr'; // https://github.com/se-panfilov/mini-toastr
import axios from 'axios';
//Vue.use(VRuntimeTemplate);
const toastTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
  warn: 'warn'
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      total: 10,
      template:` <progress :height="20" :value='total' color="blue" />`
    };
  },
  components: {

    Datatable,
    mdbProgress,
    VRuntimeTemplate
  },
  
  created: function() {
  
  },
  methods: {
    isNull(obj) {
      if (typeof obj === 'undefined' || obj === null || obj === 'null') {
        return true;
      }
      return false;
    },
    upload() {
    var t =  this.total;
    

    var config = {
          baseURL: this.$store.state.baseUrl,
          headers: {
          'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function(progressEvent){
          var percentCompleted = Math.round((progressEvent.loaded * 100)/ progressEvent.total);
          }
    }


    var formData = new FormData();
    var imagefile = document.querySelector('#fileUploader');
    formData.append("file", imagefile.files[0]);

    axios.post('UpdatePricing', formData, config);

  }

  }
};
</script>
<style scoped>
.UsersThumbnail {
  height: 50px;
}
</style>
